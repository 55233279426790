<template>
  <div class="documents-and-links-wrapper">
    <div class="documents flex flex-wrap justify-start">
      <app-card-with-link
        v-for="(document, index) in documents"
        :key="index"
        :title="document.title"
        :description="document.description"
        :link-url="document.url"
        :link-title="documentLinkText"
        class="app-card-with-link-component mb-72"
      />
    </div>
    <div v-if="links" class="links flex flex-wrap justify-start">
      <app-card-with-link
        v-for="(link, index) in links"
        :key="index"
        :title="link.title"
        :description="link.description"
        :link-url="link.url"
        :link-title="documentLinkText"
        class="app-card-with-link-component mb-72"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentsAndLinksWrapper',
  components: {
    AppCardWithLink: () => import('@/components/AppCardWithLink.vue'),
  },
  props: {
    documents: {
      required: true,
      type: Array,
    },
    links: {
      type: Array,
    },
    documentLinkText: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.documents-and-links-wrapper {
  min-height: 291px;

  .app-card-with-link {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
    margin-right: 5%;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
}
</style>
